import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/common/Layout'

const BooksIndex = ({data}) => {
  const books = data.allMarkdownRemark.edges
  return (
    <Layout booksPage>
      <section className="w-full flex justify-center">
        <div className="flex justify-center flex-wrap mt-8 max-w-3xl lg:mr-48">
          {books.map(book => {
            const { title, thumbnail } = book.node.frontmatter
            const { slug } = book.node.fields
            return (
              <Link key={slug} to={slug}>
                <img className="m-4 shadow-md" key={title} src={thumbnail} />
              </Link>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
{
  allMarkdownRemark(sort: {
    order: ASC, 
    fields: frontmatter___discussionDate 
  }) {
    edges {
      node {
        frontmatter {
          title
          thumbnail
        }
        fields {
          slug
        }
      }
    }
  }
}
`

export default BooksIndex
